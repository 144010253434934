import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import deepPurple from '@material-ui/core/colors/deepPurple';
import { Seperator } from 'components';
import { Form, TextField, createValidator, mustBeEmail, notEmpty, passwordPolicy, mustMatch } from 'components/form';
import { FacebookButton } from 'components/psg';
import { selectAuth } from 'app';
import { signinFacebook, signup } from 'app/authSlice';
import { WaitButton } from '../WaitButton';
import { AuthPage } from './AuthPage';
import woman_signup from './woman_signup.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      margin: theme.spacing(1, 0),
    },
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

type FormData = {
  email: string;
  password: string;
  verifyPassword: string;
};

const initial: FormData = {
  email: '',
  password: '',
  verifyPassword: '',
};

const validate = createValidator<FormData>({
  email: [notEmpty('Email can not be empty'), mustBeEmail('Invalid email address')],
  password: [notEmpty('Password can not be empty!'), passwordPolicy()],
  verifyPassword: mustMatch('password'),
});

export function Signup() {
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);
  const classes = useStyles();
  const handleSumbit = (data: FormData) => {
    const { email, password } = data;
    dispatch(signup({ email, password }));
  };
  return (
    <AuthPage img={<img src={woman_signup} />} headercolor={deepPurple[300]} bgcolor={deepPurple[500]}>
      <Box p={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={380}>
        <Typography variant="h6" className={classes.heading}>
          SIGN UP
        </Typography>

        <Box mt={2} width="100%" display="flex" flexDirection="column">
          <Typography color="textSecondary" variant="body2" align="center" paragraph>
            Register using your Facebook account to join our online peer community
          </Typography>

          <FacebookButton onClick={() => dispatch(signinFacebook())} disabled={status?.type === 'request'} fullWidth>
            Sign up with Facebook
          </FacebookButton>
          <Seperator>OR</Seperator>
        </Box>

        <Form<FormData> initial={initial} onSubmit={handleSumbit} validate={validate}>
          <Typography color="textSecondary" variant="body2" align="center" paragraph>
            Register with your email and select a password
          </Typography>

          <TextField
            name="email"
            className={classes.field}
            variant="outlined"
            label="Email address"
            fullWidth
            required
          />
          <TextField
            name="password"
            type="password"
            className={classes.field}
            variant="outlined"
            label="Password"
            fullWidth
            required
          />
          <TextField
            name="verifyPassword"
            type="password"
            className={classes.field}
            variant="outlined"
            label="Verify your password"
            fullWidth
            required
          />
          <Box mt={2}>
            <WaitButton wait={status?.type === 'request'} variant="contained" color="primary" type="submit" fullWidth>
              Register
            </WaitButton>
          </Box>
          <Box mt={2}>
            <Typography color="textSecondary" variant="body2" align="center">
              Already have an account? Please <Link to="/auth/signin">Sign in</Link>
            </Typography>
          </Box>
        </Form>
      </Box>
    </AuthPage>
  );
}
